import PropTypes from "prop-types";
import "./FrameComponent4.css";
import React from "react";

const FrameComponent4 = ({ className = "" }) => {
  return (
    <div className={`description-separator-parent ${className}`}>
      <div className="description-separator">
        <div className="model-details">
          <div className="model-heading">
            <div className="model-heading-child" />
            <div id="layanan" className="model-bisnis-kami">Model Bisnis Kami</div>
          </div>
          <div className="model-content">
            <div className="model-content-child" />
          </div>
        </div>
      </div>
      <div className="details-container">
        <div className="image-description">
          <h1 className="model-bisnis-kolaboratif-container">
            <p className="model-bisnis-kolaboratif">{`Model Bisnis Kolaboratif `}</p>
            <p className="model-bisnis-kolaboratif">
              PT Bayutama: Menciptakan Nilai Tambah dan Kepuasan Pelanggan
            </p>
          </h1>
          <div className="pt-bayutama-mengembangkan">
            PT Bayutama mengembangkan model bisnis yang memungkinkan di satu
            sisi mendapatkan nilai tambah usaha yang lebih baik bagi pemegang
            saham dan mitra strategis, dan di sisi lain dapat memberikan produk
            dan layanan sesuai dengan keinginan kastemer serta pasar dengan
            harga kompetitif, yaitu melalui kerjasama pengelolaan kebun sebagai
            basis produksi dan proses pengolahan produk.
          </div>
          <button className="explore-button">
            <div className="explore-button-child" />
            <div className="layanan-kami1">Layanan Kami</div>
          </button>
        </div>
        <div className="image">
          <div className="image-child" />
          <img
            className="tea-garden-2692217-1920-1-icon"
            loading="lazy"
            alt=""
            src="/teagarden2692217-1920-1@2x.png"
          />
        </div>
      </div>
    </div>
  );
};

FrameComponent4.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent4;
