import FrameComponent3 from "../components/FrameComponent3";
import FrameComponent4 from "../components/FrameComponent4";
import CallToAction from "../components/CallToAction";
import "./BayutamaWebsite.css";
import React from "react";

const BayutamaWebsite = () => {
  return (
    <div className="bayutama-website">
      <FrameComponent3 />
      <section className="partners">
        <FrameComponent4 />
      </section>
      <CallToAction />
      <section className="call-to-action">
        <div className="cta">
          <div className="cta-child" />
          <div className="button-container">
            <div className="button-content">
              <div className="call-to-action-button">
                <div className="mulai-sekarang">{`MULAI SEKARANG `}</div>
                <div className="call-to-action-button-inner">
                  <div className="frame-child" />
                </div>
              </div>
              <div className="collaboration">
                <h1 className="mari-kolaborasikan-bisnis-container">
                  <p className="mari-kolaborasikan">{`Mari Kolaborasikan `}</p>
                  <p className="mari-kolaborasikan">Bisnis Kita</p>
                </h1>
              </div>
              <div className="button-content1">
                <div className="email">
                  <div className="email-child" />
                  <div className="hubungi-kami-sekarang">
                    Hubungi Kami Sekarang
                  </div>
                </div>
                <button className="get-started">
                  <div className="get-started-child" />
                  <div className="mulai">
                  <a href="https://api.whatsapp.com/send/?phone=6287845980133&text&type=phone_number&app_absent=0">Mulai</a>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <img
            className="image-2-icon"
            loading="lazy"
            alt=""
            src="/image-2@2x.png"
          />
        </div>
      </section>
      <div className="chart" />
      <footer id="Bantuan" className="footer">
        <div className="background" />
        <div className="footer-content">
          <div className="footer-details">
            <img
              className="color-logo-optimized"
              loading="lazy"
              alt=""
              src="/color-logo-optimized@2x.png"
            />
            <div className="pt-bayutama-artha-putramandiri-wrapper">
              <div className="pt-bayutama-artha">
                PT Bayutama Artha Putramandiri (PT Bayutama) adalah perusahaan
                yang bergerak dalam bidang agribisnis (pertanian, perkebunan,
                perikanan, kehutanan), mengelola perkebunan sebagai basis
                produksi dan industri pengolahan, serta perdagangan besar
                komoditas pertanian/perkebunan dan komoditas lainnya.
              </div>
            </div>
            <div className="company-info">
              <div className="contact-info">
                <div className="twitter-parent">
                  <div className="facebook-link">
                    <img
                      className="facebook-icon"
                      loading="lazy"
                      alt=""
                      src="/frame-1.svg"
                    />
                    <div className="facebook">Facebook</div>
                  </div>
                </div>
                <div className="facebook-icon-wrapper">
                  <div className="instagram-parent">
                    <img
                      className="twitter-icon"
                      loading="lazy"
                      alt=""
                      src="/frame-2.svg"
                    />
                  </div>
                  <b className="twitter">Twitter</b>
                </div>
                <div className="facebook-wrapper">
                  <div className="facebook-icon-wrapper">
                    <img
                      className="instagram-icon"
                      loading="lazy"
                      alt=""
                      src="/frame-3.svg"
                    />
                    <div className="instagram-parent">
                      <div className="instagram">Instagram</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="social-media">
            <div className="footer-top-left-content">
              <b className="layanan-kami">Layanan Kami</b>
              <div className="tea-planning">Tea Planning</div>
              <div className="tea-planning">Coffe Planning</div>
              <div className="tea-planning">Consultation</div>
              <div className="tea-planning">
                <p className="mari-kolaborasikan">{`Commodity `}</p>
                <p className="mari-kolaborasikan">Management</p>
              </div>
            </div>
          </div>
          <div className="description">
            <div className="page-links-column">
              <div className="services">
                <b className="halaman">Halaman</b>
                <div className="tea-planning">Beranda</div>
                <div className="tea-planning">Layanan</div>
                <div className="tea-planning">Kontak</div>
                <div className="tea-planning">Bantuan</div>
              </div>
              <div className="navigation">
                <b className="kontak-kami">Kontak Kami</b>
                <div className="bayutama0424gmailcom">
                  Bayutama0424@Gmail.com
                </div>
                <div className="notaris">+62 878-4598-0133</div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright-content">
            <div className="footer-divider" />
            <div className="bayutama-2024-all-rights-res-parent">
              <div className="bayutama-2024">
                BAYUTAMA 2024 © All rights reserved
              </div>
              <div className="legal-links">
                <div className="syarat-ketentuan">{`Syarat & Ketentuan`}</div>
                <div className="syarat-ketentuan">Kebijakan Privasi</div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default BayutamaWebsite;
