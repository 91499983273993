import { useMemo } from "react";
import PropTypes from "prop-types";
import "./CallToAction.css";
import React from "react";

const CallToAction = ({
  className = "",
  callToActionPosition,
  callToActionAlignSelf,
}) => {
  const callToActionStyle = useMemo(() => {
    return {
      position: callToActionPosition,
      alignSelf: callToActionAlignSelf,
    };
  }, [callToActionPosition, callToActionAlignSelf]);

  return (
    <section
      className={`call-to-action1 ${className}`}
      style={callToActionStyle}
    >
      <div className="model-content-parent">
        <div className="model-content1">
          <div className="bank-icon">
            <div className="image1">
              <div className="image-item" />
              <img
                className="leaf-1375470-1280-1-icon"
                loading="lazy"
                alt=""
                src="/leaf1375470-1280-1@2x.png"
              />
            </div>
          </div>
          <div className="services-right">
            <div className="management-header">
              <div className="services-list-one-parent">
                <div className="services-list-one">
                  <div className="partners-heading">
                    <div className="layanan-right">
                      <div className="group-div">
                        <div className="frame-child5" />
                        <div className="layanan-kami2">Layanan Kami</div>
                      </div>
                      <div className="description-wrapper">
                        <div className="services-separator" />
                      </div>
                    </div>
                    <h1 className="memperkuat-posisi-di">
                      Memperkuat Posisi di Pasar Agribisnis: PT BAYUTAMA
                      Menawarkan Solusi Terpadu
                    </h1>
                  </div>
                </div>
                <div className="dalam-menjalankan-usahanya">
                  Dalam menjalankan usahanya, PT Bayutama menjalin kerjasama
                  dengan Perusahaan Perkebunan (PBN dan PBS) dalam pengelolaan
                  kebun dan/atau pabrik teh, antara lain Kebun Teh Jolotigo
                  Pekalongan PTP Nusantara I Region 3, di mana seluruh produksi
                  teh diserap dan dipasarkan oleh PT Bayutama. Selain itu PT
                  Bayutama juga memproses implementasi sistem manajemen mutu
                  keberlanjutan (sustainability certification) dengan menerapkan
                  Standar Teh Indonesia (STI) CerTEAfied.
                </div>
              </div>
            </div>
            <div className="frame-group">
              <div className="frame-container">
                <div className="frame-div">
                  <div className="rectangle-parent1">
                    <div className="frame-child6" />
                    <img
                      className="fi-rr-dollar-icon"
                      loading="lazy"
                      alt=""
                      src="/firrdollar.svg"
                    />
                  </div>
                  <div className="frame-parent1">
                    <div className="frame-parent2">
                      <div className="rectangle-parent2">
                        <div className="frame-child7" />
                        <img
                          className="fi-rr-home-icon"
                          loading="lazy"
                          alt=""
                          src="/firrhome.svg"
                        />
                      </div>
                      <div className="rectangle-parent3">
                        <div className="frame-child8" />
                        <img
                          className="fi-rr-bank-icon"
                          loading="lazy"
                          alt=""
                          src="/firrbank.svg"
                        />
                      </div>
                    </div>
                    <div className="wrapper1">
                      <div className="rectangle-parent3">
                        <div className="frame-child8" />
                        <img
                          className="fi-rr-bank-icon"
                          alt=""
                          src="/firrbank.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="navigation3">
                  <div className="jasa-kontrak-manajemen-kebunp-parent">
                    <div className="jasa-kontrak-manajemen">
                      Jasa kontrak manajemen Kebun/Pabrik Teh (KSO/KSU)
                    </div>
                    <div className="frame-parent3">
                      <div className="model-separator-parent">
                        <div className="model-separator1">
                          <div className="tea-processing-and">
                            Tea processing and Tea blending
                          </div>
                        </div>
                        <div className="pemasaran-teh-untuk">
                          Pemasaran teh untuk pasar domestik dan ekspor
                        </div>
                      </div>
                      <div className="nav-links">
                        <div className="tea-trading">Tea Trading</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="services-container">
                <div className="firrbank-two-placeholder-one">
                  <img
                    className="firrbank-two-placeholder-one-child"
                    loading="lazy"
                    alt=""
                    src="/group-84.svg"
                  />
                </div>
                <div className="frame-parent4">
                  <div className="rectangle-parent5">
                    <div className="frame-child10" />
                    <img
                      className="fi-rr-bank-icon2"
                      alt=""
                      src="/firrbank-2.svg"
                    />
                  </div>
                  <div className="firrbank-four-wrapper">
                    <div className="frame-parent5">
                      <div className="rectangle-parent6">
                        <div className="frame-child11" />
                        <img
                          className="fi-rr-bank-icon3"
                          alt=""
                          src="/firrbank-3.svg"
                        />
                      </div>
                      <div className="frame-wrapper">
                        <div className="rectangle-parent7">
                          <div className="frame-child12" />
                          <img
                            className="fi-rr-bank-icon4"
                            alt=""
                            src="/firrbank-4.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-wrapper1">
                <div className="service-two-item-four-wrapper-parent">
                  <div className="service-two-item-four-wrapper">
                    <div className="supplier-barang-bahan">
                      Supplier barang bahan penunjang produksi
                      pertanian/perkebunan
                    </div>
                  </div>
                  <div className="supplier-services">
                    <div className="kerjasama-off-take">
                      Kerjasama Off Take produksi Pabrik Teh
                    </div>
                    <div className="services-two-row">
                      <div className="produksi-dan-pemasaran-produk-parent">
                        <div className="produksi-dan-pemasaran">
                          Produksi dan pemasaran produk pupuk
                        </div>
                        <div className="service-two-item-three-wrapper">
                          <div className="tea-trading">
                            Jasa dan layanan lainnya
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="availability">
          <div className="management-title">
            <div className="management-title1">
              <div id="kontak" className="manajemen-perusahaan">Manajemen Perusahaan</div>
              <div className="management-title-inner">
                <img
                  className="frame-child13"
                  loading="lazy"
                  alt=""
                  src="/line-9.svg"
                />
              </div>
            </div>
          </div>
          <div className="testimonial">
            <h1 className="expertise-dan-dedikasi-container">
              <p className="expertise-dan-dedikasi">{`Expertise dan Dedikasi: `}</p>
              <p className="expertise-dan-dedikasi">
                Profil Pimpinan PT Bayutama Artha Putramandiri
              </p>
            </h1>
            <div className="testimonials">
              <div className="feedback-1">
                <div className="feedback-1-child" />
                <div className="testimonial-blocks">
                  <div className="tri-bagus-santoso-parent">
                    <b className="tri-dewi-artati">Tri Dewi Artati</b>
                    <div className="komisaris">Komisaris</div>
                  </div>
                </div>
                <div className="testimonial-quotes">
                  <h1 className="h1">“</h1>
                  <div className="lebih-dari-sekadar">
                    "Lebih dari sekadar perusahaan agribisnis, PT BAP adalah
                    mitra terpercaya yang berkomitmen untuk menghadirkan solusi
                    inovatif dan berkelanjutan dalam setiap langkah, dari hulu
                    ke hilir.
                  </div>
                </div>
              </div>
              <div className="feedback-2">
                <div className="feedback-2-child" />
                <div className="feedback-2-inner">
                  <div className="suryana-sofyan-parent">
                    <b className="tri-dewi-artati">Suryana Sofyan</b>
                    <div className="direktur-utama">Direktur Utama</div>
                  </div>
                </div>
                <div className="parent">
                  <h1 className="h11">“</h1>
                  <div className="kami-bertekad-untuk-container">
                    <p className="expertise-dan-dedikasi">
                      "Kami bertekad untuk menjadi pemimpin industri agribisnis
                      yang terpercaya, dengan fokus
                    </p>
                    <p className="expertise-dan-dedikasi">
                      pada inovasi dan keberlanjutan dalam setiap aspek
                      operasional kami.
                    </p>
                  </div>
                </div>
              </div>
              <div className="feedback-3">
                <div className="feedback-1-child" />
                <div className="feedback-3-inner">
                  <div className="tri-bagus-santoso-parent">
                    <b className="tri-dewi-artati">Tri Bagus Santoso</b>
                    <div className="direktur">Direktur</div>
                  </div>
                </div>
                <div className="testimonial-quotes">
                  <h1 className="h12">“</h1>
                  <div className="lebih-dari-sekadar">
                    <p className="expertise-dan-dedikasi">
                      "Sebagai pelaku usaha industri agribisnis, kami akan terus
                      berinovasi dan beradaptasi
                    </p>
                    <p className="expertise-dan-dedikasi">
                      dengan perubahan tren dan preferensi pasar, sehingga dapat
                      mempertahankan keunggulan
                    </p>
                    <p className="expertise-dan-dedikasi">kompetitif kami.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nav-links1">
          <h1 className="perusahaan-kami-memberikan">
            Perusahaan Kami Memberikan Layanan Kapan Saja dan Dalam Situasi Apa
            Pun
          </h1>
          <div className="pendampingan">
            <div className="expertise-icon-containers-parent">
              <div className="expertise-icon-containers">
                <div className="expert-icons" />
                <img
                  className="fi-rr-graduation-cap-icon"
                  loading="lazy"
                  alt=""
                  src="/firrgraduationcap.svg"
                />
              </div>
              <div className="jasa-kontrak-manajemen-kebunp-parent">
                <h3 className="pendampingan-ahli">Pendampingan Ahli</h3>
                <div className="tim-ahli-kami">
                  Tim ahli kami siap memberikan pendampingan yang maksimal untuk
                  membantu Anda mencapai tujuan Anda.
                </div>
              </div>
            </div>
            <div className="expertise-icon-containers-parent">
              <div className="expertise-icon-containers">
                <div className="frame-child14" />
                <img
                  className="fi-rr-graduation-cap-icon"
                  loading="lazy"
                  alt=""
                  src="/firrshieldcheck.svg"
                />
              </div>
              <div className="jasa-kontrak-manajemen-kebunp-parent">
                <h3 className="manajemen-resiko">Manajemen Resiko</h3>
                <div className="tim-ahli-kami">
                  Manajemen risiko yang efektif adalah kunci untuk mencapai
                  kesuksesan dalam bisnis.
                </div>
              </div>
            </div>
            <div className="expertise-icon-containers-parent">
              <div className="expertise-icon-containers">
                <div className="frame-child15" />
                <img
                  className="fi-rr-graduation-cap-icon"
                  loading="lazy"
                  alt=""
                  src="/firrappsadd.svg"
                />
              </div>
              <div className="solusi-sesuai-kebutuhan-parent">
                <h3 className="solusi-sesuai-kebutuhan">
                  Solusi Sesuai Kebutuhan
                </h3>
                <div className="tim-ahli-kami">
                  Kami menawarkan solusi sesuai kebutuhan untuk membantu Anda
                  mencapai tujuan Anda.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

CallToAction.propTypes = {
  className: PropTypes.string,

  /** Style props */
  callToActionPosition: PropTypes.any,
  callToActionAlignSelf: PropTypes.any,
};

export default CallToAction;
