import { useMemo } from "react";
import PropTypes from "prop-types";
import "./FrameComponent3.css";
import React from "react";

const FrameComponent3 = ({
  className = "",
  frameSectionPosition,
  frameSectionAlignSelf,
}) => {
  const modelStyle = useMemo(() => {
    return {
      position: frameSectionPosition,
      alignSelf: frameSectionAlignSelf,
    };
  }, [frameSectionPosition, frameSectionAlignSelf]);

  return (
    <section className={`model ${className}`} style={modelStyle}>
      <div className="frame-parent">
        <header className="logo-parent">
          <div className="logo">
            <img
              className="bayutama-hitam-1"
              alt=""
              src="/bayutama-hitam-1@2x.png"
            />
          </div>
          <div className="navigation1">
            <div className="menu">
              <div className="services-link">
                <div className="beranda1">
                  <a href="#beranda">Beranda</a>
                </div>
              </div>
              <div className="services-link">
                <div className="layanan1">
                  <a href="#layanan">Layanan</a>
                </div>
              </div>
              <div className="services-link">
                <div className="kontak1"> 
                  <a href="#kontak">Kontak</a>
                </div>
              </div>
              <div className="partners-container">
                <div className="profile-link">
                  <div className="beranda1"> 
                    <a href="#Bantuan">Bantuan</a>
                  </div>
                </div>
                <div className="home-contact1">
                  <div className="profil-perusahaan">
                    <a href="https://drive.google.com/file/d/1ipvMHGp0meDd5S_Jeuh-WCaYgxJRmYmA/view?usp=sharing">Profil Perusahaan</a>
                  </div>
                </div>
                <button className="rectangle-parent">
                  <div className="frame-item" />
                  <div className="mulai1">
                  <a href="https://api.whatsapp.com/send/?phone=6287845980133&text&type=phone_number&app_absent=0">Mulai</a>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </header>
        <div id="Beranda" className="model-separator">
          <div className="model-description">
            <div className="header">
              <div className="form-fields">
                <div className="hero-headings">
                  <h1 className="heading">
                    <span>{`Solusi `}</span>
                    <span className="agribisnis">Agribisnis</span>
                    <span>{` Terpercaya Untuk Kebutuhan Anda. `}</span>
                  </h1>
                  <div className="sub-heading">
                    Menawarkan layanan menyeluruh dari hulu ke hilir, mulai dari
                    pengelolaan kebun hingga pengolahan dan perdagangan
                    komoditas.
                  </div>
                </div>
                <div className="hero-contact">
                  <div className="contact-info1">
                    <div className="email1">
                      <div className="email-item" />
                      <img
                        className="phone-31172-1-icon"
                        loading="lazy"
                        alt=""
                        src="/phone31172-1.svg"
                      />
                      <div className="consult-container">
                        <div className="konsultasikan-langsung">
                          Konsultasikan Langsung
                        </div>
                      </div>
                    </div>
                    <button className="get-started1">
                      <div className="get-started-item" />
                      <div className="mulai2">
                      <a href="https://api.whatsapp.com/send/?phone=6287845980133&text&type=phone_number&app_absent=0">Mulai</a>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="rectangle-group">
              <div className="frame-inner" />
              <div className="growth-content">
                <div className="rectangle-container">
                  <div className="rectangle-div" />
                  <div className="testimonial-content">
                    <div className="growth-bars">
                      <div className="tea-trading-growth">
                        Tea Trading Growth
                      </div>
                      <div className="trading-growth">
                        <div className="bars-parent">
                          <div className="bars" />
                          <div className="bars1" />
                          <div className="bars2" />
                        </div>
                      </div>
                    </div>
                    <div className="bar" />
                  </div>
                  <div className="trading-data">
                    <div className="growth-title">
                      <div className="wrapper">
                        <div className="div">100</div>
                      </div>
                      <div className="div">200</div>
                      <div className="div">300</div>
                      <div className="div">400</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="chart-1">
                <div className="chart-1-child" />
                <div className="axis-labels">
                  <div className="label">
                    <div className="point-parent">
                      <img className="point-icon" alt="" src="/point.svg" />
                      <img
                        className="line-icon"
                        loading="lazy"
                        alt=""
                        src="/line-6.svg"
                      />
                      <img
                        className="frame-child1"
                        loading="lazy"
                        alt=""
                        src="/line-5.svg"
                      />
                      <div className="chart-label">
                        <img
                          className="connector-icon"
                          alt=""
                          src="/connector.svg"
                        />
                        <div className="ellipse-parent">
                          <div className="ellipse-div" />
                          <div className="frame-child2" />
                        </div>
                        <div className="data-label">
                          <div className="data-label-child" />
                          <div className="div4">24.08</div>
                        </div>
                        <div className="ellipse-group">
                          <div className="frame-child3" />
                          <div className="frame-child4" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="chart-label1">
                    <div className="chart-label-child" />
                    <div className="div5">48.09</div>
                  </div>
                </div>
                <div className="dots">
                  <div className="dots-container">
                    <div className="grid-item">10</div>
                    <div className="grid-item">20</div>
                    <div className="grid-item">30</div>
                    <div className="grid-item">40</div>
                    <div className="grid-item">50</div>
                    <div className="grid-item">60</div>
                    <div className="grid-item">70</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent3.propTypes = {
  className: PropTypes.string,

  /** Style props */
  frameSectionPosition: PropTypes.any,
  frameSectionAlignSelf: PropTypes.any,
};

export default FrameComponent3;
